import { createSlice } from '@reduxjs/toolkit';
export const initialState = {
    driveData: null,
    loading: false,
    error: null,
};
export const driveSlice = createSlice({
    name: 'driveDetails',
    initialState,
    reducers: {
        fetchDriveDetails: (state, _action) => {
            state.loading = true;
        },
        driveDetailsSuccess: (state, action) => {
            state.loading = false;
            state.driveData = action.payload;
        },
    },
});
export const { fetchDriveDetails, driveDetailsSuccess } = driveSlice.actions;
export default driveSlice.reducer;
