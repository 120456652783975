import { gql } from '@apollo/client';
export const GET_GUEST_OCCURRENCE_ID_QUERY = gql `
  query getDriveOccurrenceGuest($id: Int!) {
    sch_drive_occurrence_guest(where: { id: { _eq: $id } }) {
      from
      to
      status
      id
      drive_occurrence_id
      drive_occurrence {
        drive_schedule_id
        drive_schedule {
          end_date
          start_date
          status
          timezone
        }
        end_date
        start_date
        status
      }
    }
  }
`;
export const GET_DRIVE_OCCURRENCE_GUEST_QUERY = gql `
  query canxGetDriveOccurrenceGuest($drive_occurrence_guest_id: Int!) {
    canx_get_drive_occurrence_guest(drive_occurrence_guest_id: $drive_occurrence_guest_id) {
      id
      from
      to
      status
      meeting {
        id
      }
      pay_item {
        id
        status
        order_items {
          item_id
          order_id
          unit_price
          total_price
          quantity
          item {
            id
            status
          }
          order {
            id
            status
          }
        }
        drive_occurrence_guest {
          id
          from
          to
        }
        meeting_participant {
          id
          name
        }
        workflow_candidate_step {
          id
          step_name
        }
        sku {
          id
          name
          price
        }
        applied_refund_rule {
          id
          rule_name
        }
      }
      drive_occurrence {
        id
      }
      drive_schedule {
        id
      }
    }
  }
`;
