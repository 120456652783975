import { DateTime } from 'luxon';
export const parseDateTime = (date) => (date && DateTime.fromISO(date).isValid ? DateTime.fromISO(date) : null);
export const parseJSDateTime = (date) => date && DateTime.fromJSDate(date).isValid ? DateTime.fromJSDate(date) : null;
export const getCurrentDate = () => {
    const testDate = '2022-12-07T12:00:00+05:30';
    const env = process.env.NODE_ENV;
    if (env === 'test' || process.env.STORYBOOK_APP_ENV === 'storybook') {
        return parseDateTime(testDate) || DateTime.now();
    }
    return DateTime.now();
};
// UTC case
export const parseDateTimeUTC = (date) => date && DateTime.fromISO(date).isValid ? DateTime.fromISO(date).toUTC() : null;
export const getCurrentDateUTC = () => {
    const testDate = '2023-12-01T12:00:00Z';
    const env = process.env.NODE_ENV;
    if (env === 'test' || process.env.STORYBOOK_APP_ENV === 'storybook') {
        return parseDateTimeUTC(testDate) || DateTime.now().toUTC();
    }
    return DateTime.now().toUTC();
};
export const dateObjectToISOString = (date) => {
    if (!date.year || !date.month || !date.day)
        return null;
    const dateTime = DateTime.fromObject({
        year: date.year,
        month: date.month,
        day: date.day,
        hour: date.hour || 0,
        minute: date.minute || 0,
        second: date.second || 0,
    }, { zone: date.zone || 'utc' });
    return dateTime.toISO();
};
export const dateTimeToDateObject = (dateTime) => ({
    year: dateTime.year,
    month: dateTime.month,
    day: dateTime.day,
    hour: dateTime.hour,
    minute: dateTime.minute,
    second: dateTime.second,
    zone: dateTime.zoneName,
});
export const dateObjectToDateTime = (dateObject) => {
    if (!dateObject.year || !dateObject.month || !dateObject.day)
        return null;
    return DateTime.fromObject({
        year: dateObject.year,
        month: dateObject.month,
        day: dateObject.day,
        hour: dateObject.hour || 0,
        minute: dateObject.minute || 0,
        second: dateObject.second || 0,
    }, { zone: dateObject.zone || 'utc' });
};
export const dateTimeToISO = (dateTime) => dateTime.toUTC().toISO();
export const dateTimeToDayProps = (dateTime) => ({
    date: dateTime.toUTC().toISO() || '',
    day: dateTime.day,
    hour: dateTime.hour,
    minute: dateTime.minute,
    second: dateTime.second,
    isPastDate: dateTime < DateTime.now().toUTC(),
    isToday: dateTime.hasSame(DateTime.now().toUTC(), 'day'),
    isCurrentMonth: dateTime.hasSame(DateTime.now().toUTC(), 'month'),
    isMinDate: false,
    isMaxDate: false,
});
export const formatDate = (dateString) => {
    const date = DateTime.fromISO(dateString, { zone: 'utc' });
    const day = date.day;
    const month = date.monthLong;
    const weekday = date.weekdayLong;
    return `${day} ${month}, ${weekday}`;
};
export const formatIsoDateToLongDate = (isoDate) => {
    const date = DateTime.fromISO(isoDate);
    return date.toFormat('d MMMM yyyy');
};
export const formatTimeRangeInIST = (startDate, endDate) => {
    const start = parseDateTime(startDate);
    const end = parseDateTime(endDate);
    if (!start || !end) {
        return 'Invalid date range';
    }
    const startTime = start.toFormat('h:mm a');
    const endTime = end.toFormat('h:mm a');
    const zone = start.toFormat('ZZZZ');
    return `${startTime} - ${endTime} ${getMappedGmtTimezoneToShortName(zone)}`;
};
export function getMappedGmtTimezoneToShortName(zone) {
    if (zone === 'GMT+5:30' || zone === 'Asia/Kolkata' || zone === 'Asia/Calcutta') {
        return 'IST';
    }
    return zone;
}
export const formatTimeframe = (timeframe) => {
    if (!timeframe)
        return null;
    const parts = timeframe.split(':');
    if ((parts === null || parts === void 0 ? void 0 : parts.length) === 3) {
        const hours = parts[0];
        return `${hours} hour${hours === '1' ? '' : 's'}`;
    }
    return timeframe;
};
export const getCurrentTimeInUnit = (unit = 'seconds') => {
    const now = DateTime.now().toMillis(); // Get current time in milliseconds
    switch (unit) {
        case 'milliseconds':
            return now; // Already in milliseconds
        case 'minutes':
            return Math.floor(now / (60 * 1000)); // Convert milliseconds to minutes
        case 'hours':
            return Math.floor(now / (60 * 60 * 1000)); // Convert milliseconds to hours
        case 'seconds':
        default:
            return Math.floor(now / 1000); // Convert milliseconds to seconds
    }
};
export function formatCustomLongDateWithEndTime(dateString, timeZone) {
    var _a;
    const systemTimezone = DateTime.now().zoneName;
    const date = (_a = parseDateTime(dateString)) === null || _a === void 0 ? void 0 : _a.setZone(timeZone || systemTimezone);
    if (!date) {
        return 'Invalid Date';
    }
    // Format the date to "DD Month YYYY at H:mm AM/PM"
    return `${date.toFormat("dd LLLL yyyy 'at' h:mm a")} ${getMappedGmtTimezoneToShortName(timeZone || systemTimezone)}`;
}
