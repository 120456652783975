import { call, takeLatest, fork, put } from 'redux-saga/effects';
import fetchData from '@utils/fetchData';
import { catchError } from '@utils/sentry';
import { GET_GUEST_OCCURRENCE_ID_QUERY } from './queries';
import { driveDetailsSuccess, fetchDriveDetails } from './slice';
import { first } from 'lodash';
export function* fetchDriveOccurrenceGuestWorker(action) {
    const { driveOccurrenceGuestId, callback } = action.payload;
    try {
        const driveDetailResponse = (yield call(fetchData, {
            queryString: GET_GUEST_OCCURRENCE_ID_QUERY,
            queryVariables: { id: driveOccurrenceGuestId },
            queryKey: 'sch_drive_occurrence_guest',
            forceRefresh: true,
        }));
        if (!Array.isArray(driveDetailResponse) || driveDetailResponse.length === 0) {
            throw new Error('An error occurred while fetching Drive details: No data found.');
        }
        const firstDriveOccurrence = first(driveDetailResponse);
        if (!firstDriveOccurrence) {
            throw new Error('The first drive occurrence has undefined properties.');
        }
        yield put(driveDetailsSuccess(firstDriveOccurrence));
        if (callback === null || callback === void 0 ? void 0 : callback.onSuccess) {
            yield call(callback.onSuccess, driveDetailResponse);
        }
    }
    catch (error) {
        yield call(catchError, { title: 'Fetch Drive Details Worker', error: error, skipToast: true });
        if (callback === null || callback === void 0 ? void 0 : callback.onError) {
            yield call(callback.onError, error);
        }
    }
}
export function* fetchDriveOccurrenceSagaWatcher() {
    yield takeLatest(fetchDriveDetails.type, fetchDriveOccurrenceGuestWorker);
}
export function* fetchDriveDetailsRootSaga() {
    yield fork(fetchDriveOccurrenceSagaWatcher);
}
